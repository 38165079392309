<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <b-overlay :show="showCategoryOverlay">
      <!-- Prodcuts -->
      <section class="grid-view wishlist-items" style="min-height: 200px">
        <b-card
          v-for="category in categories"
          :key="category.id"
          class="ecommerce-card"
          no-body
        >
          <div
            class="mt-4"
            style="text-align: center;"
          >
            <b-avatar
              size="150"
              src=""
              :text="avatarText(category.name)"
              class="card-img-top"
            />
          <!--            <b-img-->
          <!--              :alt="`${category.name}-${category.id}`"-->
          <!--              fluid-->
          <!--              class="card-img-top"-->
          <!--              :src="category.name"-->
          <!--            />-->
          </div>

          <!-- category Details -->
          <b-card-body>
            <h6 class="item-name">
              <b-link
                class="text-body text-center"
                :to="{ name: 'apps-order-flow-checkout', params: { slug: category.slug } }"
              >
                {{ category.name }}
              </b-link>
            </h6>
            <b-card-text class="item-description">
              {{ category.description }}
            </b-card-text>
          </b-card-body>

          <!-- category Actions -->
          <div class="item-options text-center">
            <b-button
              variant="primary"
              tag="a"
              class="btn-cart"
              @click="setSelectedCategory(category.id)"
            >
              <b-spinner
                v-if="showSpinner(category.id)"
                small
                class="mr-1"
              />
              <span>Selecteren</span>
            </b-button>
          </div>
        </b-card>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BCardText, BButton, BAvatar, BSpinner, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { avatarText } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BCardText,
    BButton,
    BAvatar,
    BSpinner,
    BOverlay,
  },
  props: {
    showSelectedCategorySpinner: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      categories: [],
      selectedCategoryId: null,
      showCategoryOverlay: false,
    }
  },
  beforeMount() {
    this.fetchProductCategories()
  },
  methods: {
    showSpinner(id) {
      return (this.showSelectedCategorySpinner && (this.selectedCategoryId && this.selectedCategoryId === id))
    },
    setSelectedCategory(id) {
      this.selectedCategoryId = id
      this.$emit('set-select-category', id)
    },
    fetchProductCategories() {
      this.showCategoryOverlay = true
      store
        .dispatch('apps-order-flow-checkout/fetchProductCategories', {
          sort: 'id',
        })
        .then(response => {
          this.categories = response.data.items
          this.showCategoryOverlay = false
        })
        .catch(() => {
          this.showCategoryOverlay = false
        })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
