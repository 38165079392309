<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div v-if="formsData">
      <b-row class="match-height">
        <b-col
          v-if="selectedProduct"
          cols="12"
          md="4"
        >
          <b-card
            title="Product"
          >
            <b-row>

              <!-- User Info: Left col -->
              <b-col
                cols="12"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="PackageIcon"
                        class="mr-75"
                      />
                    </th>
                    <td class="pb-50">
                      {{ selectedProduct.name }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-card
            title="Polisinformatie"
          >

            <b-row>

              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="BriefcaseIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Verzekerd Kapitaal</span>
                    </th>
                    <td class="pb-50">
                      {{ parseFloat(polisInformation.polisInformation.insuredAmount).toLocaleString("nl-NL") }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="DollarSignIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Valuta</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ polisInformation.polisInformation.currency }}
                    </td>
                  </tr>
                  <tr
                    v-if="selectedProduct.type !== 2"
                  >
                    <th class="pb-50">
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Looptijd</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ polisInformation.polisInformation.contractDuration }} jaar
                    </td>
                  </tr>
                  <tr
                    v-if="selectedProduct.type === 2"
                  >
                    <th class="pb-50">
                      <feather-icon
                        icon="CalendarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Datum indiensttreding</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ polisInformation.polisInformation.employmentDate }}
                    </td>
                  </tr>
                  <tr
                    v-if="selectedProduct.type === 2 && selectedProduct.collectiveID"
                  >
                    <th class="pb-50">
                      <feather-icon
                        icon="UsersIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Collectief</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ polisInformation.polisInformation.collectiveID }}
                    </td>
                  </tr>
                </table>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="RepeatIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Betaalfrequentie</span>
                    </th>
                    <td class="pb-50">
                      {{ polisInformation.polisInformation.paymentFrequency }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="ClockIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Startdatum</span>
                    </th>
                    <td class="pb-50">
                      {{ polisInformation.polisInformation.startDate }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Betaalmethode</span>
                    </th>
                    <td>
                      {{ polisInformation.polisInformation.paymentMethod }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          v-if="polisInformation.polisHolderData"
          cols="12"
          md="12"
        >
          <b-card
            title="Verzekeringsnemer"
          >
            <b-table
              ref="refPolisHolderSummaryListTable"
              class="position-relative"
              :items="polisInformation.polisHolderData"
              responsive
              :fields="polisHolderSummaryTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                <b-link
                  class="font-weight-bold"
                >
                  #{{ data.value }}
                </b-link>
              </template>

              <!-- Column: Relations -->
              <template #cell(relation)="data">
                <b-media
                  v-if="data.item.type === 1"
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="avatarText(getFullName(data.item))"
                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ getFullName(data.item) }}
                  </b-link>
                  <div class="text-muted">
                    {{ data.item.email }}
                  </div>
                </b-media>
                <b-media
                  v-if="data.item.type === 2"
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="avatarText(getFullName(data.item.companyName))"
                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.companyName }}
                  </b-link>
                  <div class="text-muted">
                    {{ getFullName(data.item) }}
                  </div>
                </b-media>
              </template>

              <template #cell(type)="data">
                <div class="text-nowrap">
                  <feather-icon
                    v-if="data.item.type === 2"
                    class="cursor-pointer"
                    icon="HomeIcon"
                    size="20"
                  />
                  <feather-icon
                    v-if="data.item.type === 1"
                    class="cursor-pointer"
                    icon="UserIcon"
                    size="20"
                  />
                  {{ resolveTypeVariant(data.value) }}
                </div>
                <div
                  v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
                  class="text-nowrap"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="CompassIcon"
                    size="20"
                    :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
                  />
                  {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
                </div>
              </template>

              <template #cell(phone)="data">
                <span class="text-nowrap">
                  {{ data.item.type === 1 ? data.item.mobile : data.value }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">

                <div class="text-nowrap">
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
                  >
                    <feather-icon
                      :id="`product-polis-holder-row-${data.item.id}-eye-icon`"
                      class="cursor-pointer"
                      size="16"
                      icon="EyeIcon"
                    />
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          v-if="polisInformation.insuredPartiesData"
          cols="12"
          md="12"
        >
          <b-card
            title="Verzekerde"
          >
            <b-table
              ref="refInsuredPartiesSummaryListTable"
              class="position-relative"
              :items="polisInformation.insuredPartiesData"
              responsive
              :fields="insuredPartiesSummaryTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.item.id }}
              </template>

              <!-- Column: Relations -->
              <template #cell(relation)="data">
                <b-media
                  v-if="data.item.type === 1"
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="avatarText(getFullName(data.item))"
                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ getFullName(data.item) }}
                  </b-link>
                  <div class="text-muted">
                    {{ data.item.email }}
                  </div>
                </b-media>
                <b-media
                  v-if="data.item.type === 2"
                  vertical-align="center"
                >
                  <template #aside>
                    <b-avatar
                      size="32"
                      :text="avatarText(getFullName(data.item.companyName))"
                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    />
                  </template>
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ data.item.companyName }}
                  </b-link>
                  <div class="text-muted">
                    {{ getFullName(data.item) }}
                  </div>
                </b-media>
              </template>

              <template #cell(type)="data">
                <div class="text-nowrap">
                  <feather-icon
                    v-if="data.item.type === 2"
                    class="cursor-pointer"
                    icon="HomeIcon"
                    size="20"
                  />
                  <feather-icon
                    v-if="data.item.type === 1"
                    class="cursor-pointer"
                    icon="UserIcon"
                    size="20"
                  />
                  {{ resolveTypeVariant(data.value) }}
                </div>
                <div
                  v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
                  class="text-nowrap"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="CompassIcon"
                    size="20"
                    :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
                  />
                  {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
                </div>
              </template>

              <template #cell(phone)="data">
                <span class="text-nowrap">
                  {{ data.item.type === 1 ? data.item.mobile : data.value }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">

                <div class="text-nowrap">
                  <b-link
                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
                  >
                    <feather-icon
                      :id="`product-insured-party-row-${data.item.id}-eye-icon`"
                      class="cursor-pointer"
                      size="16"
                      icon="EyeIcon"
                    />
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col
          v-if="polisInformation.benificiaries.length > 0"
          cols="12"
          md="12"
        >
          <b-card
            title="Begunstigden"
          >
            <b-table
              ref="refBenificiariesSummaryListTable"
              class="position-relative"
              :items="polisInformation.benificiaries"
              responsive
              :fields="benificiariesSummaryTableColumns"
              primary-key="id"
              show-empty
              empty-text="Geen overeenkomende records gevonden"
            >

              <template #cell(name)="data">
                {{ +data.item.type === 1 ? getFullName(data.item) : data.item.companyName }}
              </template>

            </b-table>
          </b-card>
        </b-col>
        <b-col
          v-if="selectedProduct.hasLenderOption === 1"
          cols="12"
          md="12"
        >
          <b-card
            title="Krediet & Hypotheken"
          >
            <validation-observer
              ref="refLenderFormObserver"
            >
              <b-form>
                <b-row class="mb-1 mt-0">
                  <!-- Field: Full Name -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <p><strong>Bij krediet en hypotheken komen de rechten uit de aangevraagde verzekering altijd toe aan de kredietverstrekker. Hierbij de akte of leenovereenkomstoverleggen</strong></p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="hasCredit"
                      rules="required"
                    >
                      <b-form-group
                        label="Heeft u een krediet of hypotheek?"
                        :state="getValidationState(validationContext)"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            id="hasCreditFalse"
                            v-model="credit.hasCredit"
                            name="hasCredit"
                            :value="false"
                            class="custom-control-primary"
                            :state="getValidationState(validationContext)"
                          >
                            Nee
                          </b-form-radio>
                          <b-form-radio
                            id="hasCreditTrue"
                            v-model="credit.hasCredit"
                            name="hasCredit"
                            :value="true"
                            class="custom-control-primary"
                            :state="getValidationState(validationContext)"
                          >
                            Ja
                          </b-form-radio>
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="creditProvider"
                      rules="required"
                    >
                      <b-form-group
                        label="Naam kredietverstrekker"
                        label-for="creditProvider"
                      >
                        <b-form-input
                          id="creditProvider"
                          v-model="credit.creditProvider"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="creditProviderAddress"
                      rules="required"
                    >
                      <b-form-group
                        label="Adres"
                        label-for="creditProviderAddress"
                      >
                        <b-form-input
                          id="creditProviderAddress"
                          v-model="credit.creditProviderAddress"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="creditAmount"
                      rules="required|integer"
                    >
                      <b-form-group
                        label="Hypotheek/Kredietbedrag"
                        label-for="creditAmount"
                      >
                        <b-form-input
                          id="creditAmount"
                          v-model="credit.creditAmount"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Valuta"
                      label-for="creditAmountCurrency"
                    >
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="credit.creditAmountCurrency"
                          name="creditAmountCurrency"
                          value="SRD"
                          class="custom-control-primary"
                        >
                          SRD
                        </b-form-radio>
                        <b-form-radio
                          v-model="credit.creditAmountCurrency"
                          name="creditAmountCurrency"
                          value="US"
                          class="custom-control-primary"
                        >
                          USD
                        </b-form-radio>
                        <b-form-radio
                          v-model="credit.creditAmountCurrency"
                          name="creditAmountCurrency"
                          value="EURO"
                          class="custom-control-primary"
                        >
                          EURO
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="repaymentTerms"
                      rules="required"
                    >
                      <b-form-group
                        label="Aflossingstermijn"
                        label-for="repaymentTerms"
                      >
                        <b-form-input
                          id="repaymentTerms"
                          v-model="credit.repaymentTerms"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="credit.hasCredit"
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="startingDate"
                      rules="required"
                    >
                      <b-form-group
                        label="Ingangsdatum"
                        label-for="startingDate"
                      >
                        <b-form-datepicker
                          id="startingDate"
                          v-model="credit.startingDate"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          type="text"
                          :min="new Date()"
                          label-no-date-selected="Maak een keuze"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="termsObserver"
          >
            <b-form
              ref="terms"
            >
              <b-card title="Voorwaarden">
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="acc1"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          id="acc1"
                          v-model="terms.acc1"
                          :state="errors.length > 0 ? false:null"
                        >
                          Ondergetekenden verklaren dat alle antwoorden op de gestelde vragen naar beste weten, juist en naar waarheid te hebben beantwoord dan wel zijn ingevuld en dragen kennis dat verzwijging en/of verkeerde of onvoldoende informatie de verzekeringsovereenkomst nietig verklaar kan worden. Tevens verklaren ondergetekenden dat zij bij hun aanvraag de algemene voorwaarden, welk deel uitmaakt van de verzekeringsovereenkomst, hebben doorgenomen en zich hiermee akkoord verklaren. Voorzover ondergetekenden akkoord gaan met de door Self Reliance Leven N.V. uitgebrachte offerte zullen deze voorwaarden worden verstrekt bij afgifte van het polisblad en vormen tezamen de polis. Onze polisvoorwaarden kunt u nalezen op onze website www.self-reliance.sr.
                        </b-form-checkbox>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="acc2"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          id="acc2"
                          v-model="terms.acc2"
                          :state="errors.length > 0 ? false:null"
                        >
                          Ondergetekende verzekerde verklaart dat hij/zij Self Reliance Leven N.V. toestemming geeft voor het opvragen van inlichtingen zonder enig voorbehoud omtrent de gezondeheidstoestand dan wel gestelde diagnose en/of medische historie bij de behandelende arts(en). Niettegenstaande deze machtiging ontheft het de verzekerde niet om te voldoen aan de mededelingsplicht, zoals opgenomen in de polisvoorwaarden artikel 7.
                        </b-form-checkbox>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row
        class="match-height"
      >
        <b-col
          v-for="polisHolder in polisInformation.polisHolderData"
          :key="polisHolder.id"
          cols="12"
          lg="4"
        >
          <b-card
            no-body
          >
            <b-card-header>
              <b-card-title>Handtekening: <small class="text-secondary">{{ polisHolder.type === 1 ? getFullName(polisHolder) : polisHolder.companyName }}</small></b-card-title>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </b-card-header>
            <b-card-body>
              <VueSignaturePad
                :id="polisHolder.id"
                :ref="`signaturePad${polisHolder.id}`"
                :options="{onBegin: () => {$refs[`signaturePad${polisHolder.id}`][0].resizeCanvas()}}"
                class="signature"
                width="100%"
                height="150px"
              />
              <div class="mt-2 mb-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="undo(`signaturePad${polisHolder.id}`)"
                ><span>Opnieuw</span></b-button>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-for="insuredParty in polisInformation.insuredPartiesData"
          :key="insuredParty.id"
          cols="12"
          lg="4"
        >
          <template
            v-if="selectedProduct.type === 1 || selectedProduct.type === 2 && insuredParty.id === polisInformation.polisInformation.isMainInsuredParty"
          >
            <b-card
              v-if="polisInformation.polisHolderData.some(relation => relation.id !== insuredParty.id)"
              no-body
            >
              <b-card-header>
                <b-card-title>Handtekening: <small class="text-secondary">{{ insuredParty.type === 1 ? getFullName(insuredParty) : insuredParty.companyName }}</small></b-card-title>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </b-card-header>
              <b-card-body>
                <VueSignaturePad
                  :id="insuredParty.id"
                  :ref="`signaturePad${insuredParty.id}`"
                  :options="{onBegin: () => {$refs[`signaturePad${insuredParty.id}`][0].resizeCanvas()}}"
                  class="signature"
                  width="100%"
                  height="150px"
                />
                <div class="mt-2 mb-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="undo(`signaturePad${insuredParty.id}`)"
                  ><span>Opnieuw</span></b-button>
                </div>
              </b-card-body>
            </b-card>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card>
            <b-row>
              <b-col
                cols="6 text-left"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="$emit('previous-step')"
                >
                  <span>Vorige</span>
                </b-button>
              </b-col>
              <b-col
                cols="6 text-right"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="showSubmitOrderSpinner"
                  @click="createOrder"
                >
                  <b-spinner
                    v-if="showSubmitOrderSpinner"
                    small
                    class="mr-1"
                  />
                  <span>Versturen</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BTable,
  BLink,
  BMedia,
  BAvatar,
  BFormInput,
  BFormRadio,
  BSpinner,
  BFormDatepicker,
  BFormInvalidFeedback,
  BOverlay,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import useOrderFlow from '@/views/pages/order-flow/useOrderFlow'
import { avatarText } from '@core/utils/filter'
import useRelationsList from '@/views/pages/relations/useRelationsList'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BTable,
    BLink,
    BMedia,
    BAvatar,
    BFormInput,
    BFormRadio,
    BSpinner,
    BOverlay,
    BFormDatepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    insuredParties: {
      type: Array,
      default: () => [],
    },
    polisInformation: {
      type: Object,
      default: () => {},
    },
    selectedProduct: {
      type: Object,
      default: () => {},
    },
    formsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      terms: {
        acc1: null,
        acc2: null,
      },
      signatureError: false,
      credit: {
        hasCredit: false,
        creditProvider: '',
        creditProviderAddress: '',
        creditAmount: null,
        creditAmountCurrency: 'SRD',
        repaymentTerms: '',
        startingDate: '',
      },
      showSubmitOrderSpinner: false,
      showOverlay: false,
    }
  },
  methods: {
    undo(ref) {
      this.$refs[ref][0].undoSignature()
    },
    validateSignature(ref) {
      const { isEmpty, data } = this.$refs[ref][0].saveSignature('image/svg+xml')
      return { isEmpty, data }
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    async createOrder() {
      this.showOverlay = true
      this.showSubmitOrderSpinner = true

      let insuredPartiesDataSignatureValid = false
      let polisHolderDataSignatureValid = false
      const signatures = []

      const polisHolderDataSignatureCheck = this.validateSignature(`signaturePad${this.polisInformation.polisHolderData[0].id}`)

      if (!polisHolderDataSignatureCheck.isEmpty) {
        polisHolderDataSignatureValid = true

        signatures.push({ type: 1, relationID: this.polisInformation.polisHolderData[0].id, signature: polisHolderDataSignatureCheck.data })
      }

      for (let i = 0; i < this.polisInformation.insuredPartiesData.length; i += 1) {
        const insuredPartiesSignatureCheck = this.validateSignature(`signaturePad${this.polisInformation.insuredPartiesData[i].id}`)

        if (!insuredPartiesSignatureCheck.isEmpty) {
          insuredPartiesDataSignatureValid = true
          signatures.push({ type: 2, relationID: this.polisInformation.insuredPartiesData[i].id, signature: insuredPartiesSignatureCheck.data })
        }
      }

      const offerValidators = [this.$refs.termsObserver.validate()]

      if (this.selectedProduct.hasLenderOption) {
        offerValidators.push(this.$refs.refLenderFormObserver.validate())
      }

      await Promise.all(offerValidators).then(valid => {
        if (!valid.includes(false) && insuredPartiesDataSignatureValid && polisHolderDataSignatureValid) {
          this.signatureError = false

          const orderData = {
            forUserID: this.polisInformation.polisInformation.forUserID ? this.polisInformation.polisInformation.forUserID : null,
            productID: this.selectedProduct.id,
            contractDuration: this.polisInformation.polisInformation.contractDuration,
            startDate: this.polisInformation.polisInformation.startDate,
            currency: this.polisInformation.polisInformation.currency,
            insuredAmount: this.polisInformation.polisInformation.insuredAmount,
            policyHolderRelationID: this.polisInformation.polisHolderData[0].id,
            insuredRelations: this.polisInformation.insuredPartiesId,
            flowQuestions: this.formsData,
            benificiaries: this.polisInformation.benificiaries,
            hasLender: false,
            paymentMethod: this.polisInformation.polisInformation.paymentMethod,
            paymentFrequency: this.polisInformation.polisInformation.paymentFrequency,
            signatures,
          }

          /**
           *
           * Add extra params to orderData when type is APSR
           *
           */
          if (this.selectedProduct.type === 2) {
            orderData.collectiveID = this.polisInformation.polisInformation.collectiveID ? this.polisInformation.polisInformation.collectiveID : null
            orderData.employerContributionPercentage = this.polisInformation.polisInformation.employerContributionPercentage
            orderData.additionalInsuranceType = this.polisInformation.polisInformation.additionalInsuranceType
            orderData.employmentDate = this.polisInformation.polisInformation.employmentDate
            orderData.partnerPensionType = this.polisInformation.polisInformation.partnerPensionType
          }

          store.dispatch('apps-order-flow-checkout/createOrders', orderData).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Order',
                icon: 'AlertTriangleIcon',
                variant: 'succes',
                text: 'Uw order is met succes aangemaakt.',
              },
            })

            this.showOverlay = false
            this.showSubmitOrderSpinner = false
            router.push({ name: 'app-order' })
          }).catch(() => {
            this.showOverlay = false
            this.showSubmitOrderSpinner = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Order',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorDefaultMessage.saveDataError}`,
              },
            })
          })
        } else {
          this.showOverlay = false
          this.showSubmitOrderSpinner = false
          this.signatureError = true
        }
      })
    },
  },
  setup() {
    const {
      resolveTypeVariant,
      resolveRiskProfileColor,
    } = useRelationsList()

    const {
      refInsuredPartiesSummaryListTable,
      refBenificiariesSumarryListTable,
      benificiariesSummaryTableColumns,
      insuredPartiesSummaryTableColumns,
      refPolisHolderSummaryListTable,
      polisHolderSummaryTableColumns,
    } = useOrderFlow()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      resolveTypeVariant,
      resolveRiskProfileColor,
      refInsuredPartiesSummaryListTable,
      refBenificiariesSumarryListTable,
      benificiariesSummaryTableColumns,
      insuredPartiesSummaryTableColumns,
      refPolisHolderSummaryListTable,
      polisHolderSummaryTableColumns,
      refFormObserver,
      getValidationState,
      resetForm,
      avatarText,
    }
  },
}
</script>
<style>
.signature {
  border: solid 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
  radial-gradient(circle at top left, #fdb813, #d31145);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
