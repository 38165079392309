import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}productcategories?nonEmpty=true`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductsForProductId(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchForRelations(ctx, { data }) {
      if (data.searchType === 1) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${axiosDefaultConfig.backendEndPoint}relations/globalsearch?type=1&lastName=${data.lastName}&birthDate=${data.birthDate}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }

      if (data.searchType === 2) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${axiosDefaultConfig.backendEndPoint}relations/globalsearch?type=2&companyName=${data.searchCompanyName}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      }

      return null
    },
    createOrders(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}orders`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRelation(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}relations`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRelation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}relations/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMaxDurationByRelation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products/getmaxdurationbyrelation?productID=${data.productID}&relationIDs=${data.relationIDs}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCollectives(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}collectives`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}users`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
