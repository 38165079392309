<template>
  <div style="height: inherit">
    <!-- Overlay -->
    <div class="body-content-overlay" />
    <section class="grid-view wishlist-items">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div
          class="mt-4"
          style="text-align: center;"
        >
          <b-avatar
            size="150"
            src=""
            :text="avatarText(product.name)"
            class="card-img-top"
          />
          <!--            <b-img-->
          <!--              :alt="`${category.name}-${category.id}`"-->
          <!--              fluid-->
          <!--              class="card-img-top"-->
          <!--              :src="category.name"-->
          <!--            />-->
        </div>

        <!-- category Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body text-center"
              :to="{ name: 'apps-order-flow-checkout'}"
            >
              {{ product.name }}
            </b-link>
          </h6>
        </b-card-body>

        <!-- category Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="setSelectedProduct(product)"
          >
            <span>Selecteren</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BButton, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BButton, BAvatar,

  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    setSelectedProduct(product) {
      this.$emit('set-select-product', product)
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-application .ecommerce-card .item-name a {
  display: block !important;
  -webkit-line-clamp: initial !important;
  overflow: visible !important;
  text-overflow: initial !important;
}
</style>
