<template>
  <div>
    <div
      v-for="insuredParty in insuredParties"
      :key="insuredParty.id"
    >
      <template
        v-if="selectedProduct.type === 1 || selectedProduct.type === 2 && insuredParty.id === polisInformation.polisInformation.isMainInsuredParty"
      >
        <medical-form
          :ref="`medialFormRef${insuredParty.id}`"
          :form-id="`medialForm${insuredParty.id}`"
          :relation="insuredParty"
          :index="insuredParty.id"
          @save-form-data="saveFormData"
        />
      </template>
    </div>
    <b-row
      v-if="pageHasError === true"
    >
      <b-col
        cols="12"
      >
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">Niet alle verplichte formulieren zijn ingevuld. Vul deze alsnog in, en probeer het nogmaals.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="6 text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$emit('previous-step')"
              >
                <span>Vorige</span>
              </b-button>
            </b-col>
            <b-col
              cols="6 text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onSubmit"
              >
                <span>Volgende</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import medicalForm from './forms/medical/MedicalForm.vue'

export default {
  components: {
    medicalForm,
    BButton,
    BCol,
    BRow,
    BCard,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    insuredParties: {
      type: Array,
      default: () => [],
    },
    selectedProduct: {
      type: Object,
      default: () => {},
    },
    polisInformation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: [],
      pageHasError: false,
    }
  },
  methods: {
    saveFormData(data) {
      if (!this.formData.some(relation => JSON.stringify(relation) === JSON.stringify(data))) {
        this.formData.push(data)
      } else if (this.formData.some(relation => JSON.stringify(relation) === JSON.stringify(data))) {
        const index = this.formData.findIndex(item => item.id === data.id)
        this.formData.splice(index, 1)
        this.formData.push(data)
      }
    },
    clearPageError() {
      this.pageHasError = false
    },
    onSubmit() {
      let isValid = false

      for (let i = 0; i < this.insuredParties.length; i += 1) {
        isValid = this.$refs[`medialFormRef${this.insuredParties[i].id}`][0].isValid
      }

      if (isValid) {
        this.$emit('set-forms', this.formData)
      } else {
        this.pageHasError = true
        setTimeout(this.clearPageError, 5000)
      }
    },
  },
}
</script>

<style scoped>

</style>
