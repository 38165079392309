import { ref } from '@vue/composition-api'

export default function useOrderFlow() {
  const refPolisHolderSummaryListTable = ref(null)
  const refInsuredPartiesSummaryListTable = ref(null)
  const refBenificiariesSumarryListTable = ref(null)

  const benificiariesSummaryTableColumns = [
    { label: 'Naam', key: 'name', sortable: true },
    { label: 'Geboortedatum', key: 'birthDate', sortable: true },
    { label: 'Relatie omschrijving', key: 'relationDescription', sortable: true },
  ]

  const insuredPartiesSummaryTableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'District', key: 'district', sortable: true },
    { label: 'Land', key: 'country', sortable: true },
  ]

  // Insured Parties Table Handlers
  const polisHolderSummaryTableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'District', key: 'district', sortable: true },
    { label: 'Land', key: 'country', sortable: true },
  ]

  const refInsuredPartiesListTable = ref(null)
  // Insured Parties Table Handlers
  const insuredPartiesTableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'District', key: 'district', sortable: true },
    { label: 'Land', key: 'country', sortable: true },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]

  const fetchInsuredParties = ref([])

  const refetchInsuredPartiesData = () => {
    refInsuredPartiesListTable.value.refresh()
  }

  const refPolisHolderListTable = ref(null)
  // Insured Parties Table Handlers
  const polisHolderTableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'relation', sortable: true },
    { label: 'Profiel', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phone', sortable: true },
    { label: 'District', key: 'district', sortable: true },
    { label: 'Land', key: 'country', sortable: true },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]

  // const polisHolderTableSortBy = ref('id')
  const fetchPolisHolderData = ref([])

  const refetchPolisHolderData = () => {
    refPolisHolderListTable.value.refresh()
  }

  const addInsuredParties = party => {
    fetchInsuredParties.push(party)
  }

  const countryOptions = [
    { label: 'Suriname', value: 'SR' },
  ]

  const idTypeOptions = [
    { label: 'Paspoort', value: 'Paspoort' },
    { label: 'Rijbewijs', value: 'Rijbewijs' },
    { label: 'IDkaart', value: 'IDkaart' },
  ]

  const districts = [
    {
      label: 'SR',
      value: [
        {
          label: 'Brokopondo',
          value: 'Brokopondo',
        },
        {
          label: 'Commewijne',
          value: 'Commewijne',
        },
        {
          label: 'Coronie',
          value: 'Coronie',
        },
        {
          label: 'Marowijne',
          value: 'Marowijne',
        },
        {
          label: 'Nickerie',
          value: 'Nickerie',
        },
        {
          label: 'Para',
          value: 'Para',
        },
        {
          label: 'Paramaribo',
          value: 'Paramaribo',
        },
        {
          label: 'Saramacca',
          value: 'Saramacca',
        },
        {
          label: 'Sipaliwini',
          value: 'Sipaliwini',
        },
        {
          label: 'Wanica',
          value: 'Wanica',
        },
      ],
    },
  ]

  const legalFormOptions = [
    { label: 'Naamloze Vennootschap', value: 'Naamloze Vennootschap' },
    { label: 'Eenmanszaak', value: 'Eenmanszaak' },
    { label: 'V.O.F.', value: 'V.O.F.' },
    { label: 'C.V.', value: 'C.V.' },
    { label: 'B.V.', value: 'B.V.' },
    { label: 'Stichting', value: 'Stichting' },
    { label: 'Vereniging', value: 'Vereniging' },
    { label: 'Anders', value: 'Anders' },
  ]

  const partnerInsuranceTypeOptions = [
    { label: '10 jaar', value: 2 },
    { label: 'Lifetime', value: 1 },
  ]

  const bfsTypeOptions = [
    { label: 'Afkoop', value: 1 },
    { label: 'Jaarpremie', value: 2 },
  ]

  const resolveTypeVariant = status => {
    if (status === 1) return 'Particulier'
    if (status === 2) return 'Zakelijk'
    return ''
  }

  // Change district on different list.js select
  const resolveDistrictForCountry = countryCode => districts.filter(arr => arr.label === countryCode)[0].value

  // Initial set districts
  const districtOptions = resolveDistrictForCountry('SR')

  const martialOptions = [
    { label: 'Gehuwd', value: 'Gehuwd' },
    { label: 'Ongehuwd', value: 'Ongehuwd' },
    { label: 'Concubinaat', value: 'Concubinaat' },
  ]

  return {
    refInsuredPartiesSummaryListTable,
    refBenificiariesSumarryListTable,
    benificiariesSummaryTableColumns,
    insuredPartiesSummaryTableColumns,
    refetchInsuredPartiesData,
    refetchPolisHolderData,
    resolveDistrictForCountry,
    fetchPolisHolderData,
    refPolisHolderSummaryListTable,
    polisHolderSummaryTableColumns,

    countryOptions,
    districtOptions,
    idTypeOptions,

    refInsuredPartiesListTable,
    refPolisHolderListTable,

    insuredPartiesTableColumns,
    polisHolderTableColumns,
    fetchInsuredParties,
    addInsuredParties,
    legalFormOptions,
    partnerInsuranceTypeOptions,
    resolveTypeVariant,
    martialOptions,
    bfsTypeOptions,
  }
}
