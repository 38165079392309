<template>
  <form-wizard
    ref="refFormWizard"
    color="#d31145"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- Category selection tab -->
    <tab-content
      title="Categorien"
      icon="feather icon-grid"
      class=".nav-pill-success"
    >
      <order-flow-step-category
        :show-selected-category-spinner="showSelectedCategorySpinner"
        @next-step="formWizardNextStep"
        @set-select-category="setSelectedCategory"
      />
    </tab-content>

    <!-- Product selection tab -->
    <tab-content
      title="Producten"
      icon="feather icon-package"
    >
      <order-flow-step-products
        :products="products"
        @set-select-product="setSelectedProduct"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>

    <!-- Polis information tab -->
    <tab-content
      title="Polis informatie"
      icon="feather icon-package"
    >
      <order-flow-step-polis-information
        ref="polisInformationStep"
        :max-insured-relations="selectedProductMaxInsuredRelations"
        :selected-product="selectedProduct"
        @next-step="formWizardNextStep"
        @set-polis-information="setPolisInformation"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Medisch formulier"
      icon="feather icon-file-text"
    >
      <order-flow-step-form
        :insured-parties="insuredParties"
        :selected-product="selectedProduct"
        :polis-information="polisInformation"
        @set-forms="setFormsData"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>

    <tab-content
      title="Afronden"
      icon="feather icon-shopping-bag"
    >
      <order-flow-step-summary
        :insured-parties="insuredParties"
        :polis-information="polisInformation"
        :selected-product="selectedProduct"
        :forms-data="formsData"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import orderFlowStoreModule from '@/views/pages/order-flow/orderFlowStoreModule'
import OrderFlowStepCategory from '@/views/pages/order-flow/OrderFlowStepCategory.vue'
import OrderFlowStepProducts from '@/views/pages/order-flow/OrderFlowStepProducts.vue'
import OrderFlowStepPolisInformation from '@/views/pages/order-flow/OrderFlowStepPolisInformation.vue'
import OrderFlowStepForm from '@/views/pages/order-flow/OrderFlowStepForm.vue'
import OrderFlowStepSummary from '@/views/pages/order-flow/OrderFlowStepSummary.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    OrderFlowStepCategory,
    OrderFlowStepProducts,
    OrderFlowStepPolisInformation,
    OrderFlowStepForm,
    OrderFlowStepSummary,
  },
  data() {
    return {
      categoryId: 0,
      selectedProduct: {},
      selectedProductMaxInsuredRelations: 0,
      products: [],
      existingRelations: [],
      insuredParties: [],
      polisInformation: {},
      formsData: null,
      showSelectedCategorySpinner: false,
    }
  },
  methods: {
    setSelectedCategory(id) {
      this.categoryId = id
      this.fetchProducts()
    },
    setSelectedProduct(product) {
      this.$refs.polisInformationStep.resetStep()
      this.selectedProduct = product
      this.selectedProductMaxInsuredRelations = product.maxInsuredRelations
      this.formWizardNextStep()
    },
    setPolisInformation(info) {
      this.polisInformation = info
      this.insuredParties = info.insuredPartiesData
    },
    fetchProducts() {
      this.showSelectedCategorySpinner = true
      store.dispatch('apps-order-flow-checkout/fetchProductsForProductId', { categoryID: this.categoryId, active: 1 })
        .then(response => {
          this.products = response.data.items
          this.showSelectedCategorySpinner = false
          this.formWizardNextStep()
        })
        .catch(() => {
          this.showSelectedCategorySpinner = false
        })
    },
    setFormsData(forms) {
      this.formsData = forms
      this.formWizardNextStep()
    },
  },
  setup() {
    const ORDER_FLOW_APP_STORE_MODULE_NAME = 'apps-order-flow-checkout'

    // Register module.
    if (!store.hasModule(ORDER_FLOW_APP_STORE_MODULE_NAME)) store.registerModule(ORDER_FLOW_APP_STORE_MODULE_NAME, orderFlowStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDER_FLOW_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_FLOW_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    const formWizardPreviousStep = () => {
      refFormWizard.value.prevTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPreviousStep,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked{
  box-shadow: 0 3px 6px 0 rgb(211 17 69 / 40%) !important;
}

.ecommerce-application .product-checkout.list-view {
  grid-template-columns: 2fr !important;
}
</style>
