<template>
  <div>
    <b-modal
      id="modal-add-relation"
      ref="modalAddRelation"
      hide-footer
      centered
      size="xl"
      title="Relatie toevoegen"
      :no-close-on-backdrop="true"
    >
      <b-card-text>
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Bestaande relatie</span>
            </template>
            <b-card-text>
              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <b-form
                  @submit.prevent="handleSubmit(searchForExistingRelations)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                      class="d-flex justify-content-between flex-column"
                    >
                      <b-card title="Bestaande relatie zoeken">
                        <b-row>
                          <b-col
                            cols="12"
                            md="6"
                            class="mb-1"
                          >
                            <label>Particulier / Zakelijk</label>
                            <div class="demo-inline-spacing">
                              <b-form-radio
                                v-model="relationSearchQuery.searchType"
                                class="mt-0"
                                name="searchType"
                                :value="1"
                              >
                                Particulier
                              </b-form-radio>
                              <b-form-radio
                                v-model="relationSearchQuery.searchType"
                                class="mt-0"
                                name="searchType"
                                :value="2"
                              >
                                Zakelijk
                              </b-form-radio>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col
                            v-if="relationSearchQuery.searchType === 2"
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="searchCompanyName"
                              rules="required"
                            >
                              <b-form-group
                                label="Organisatie"
                                label-for="searchCompanyName"
                              >
                                <b-form-input
                                  id="searchCompanyName"
                                  v-model="relationSearchQuery.searchCompanyName"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- Field: firstname -->
                          <b-col
                            v-if="relationSearchQuery.searchType === 1"
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="searchLastName"
                              rules="required"
                            >
                              <b-form-group
                                label="Achternaam"
                                label-for="searchLastName"
                              >
                                <b-form-input
                                  id="searchLastName"
                                  v-model="relationSearchQuery.lastName"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- Field: birthdate -->
                          <b-col
                            v-if="relationSearchQuery.searchType === 1"
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="searchBirthDate"
                              rules="required"
                            >
                              <b-form-group
                                label="Geboortedatum"
                                label-for="searchBirthDate"
                                :state="getValidationState(validationContext)"
                              >
                                <flat-pickr
                                  id="searchBirthDate"
                                  v-model="relationSearchQuery.birthDate"
                                  :config="searchBirthDateConfig"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            class="align-self-end pb-1"
                          >
                            <b-button
                              variant="primary"
                              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              type="submit"
                            >
                              Zoeken
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col
                            cols="12"
                            md="12"
                          >
                            <b-table
                              ref="refInsuredPartiesListTable"
                              class="position-relative"
                              :items="existingRelations"
                              responsive
                              :fields="insuredPartiesTableColumns"
                              show-empty
                              empty-text="Geen overeenkomende records gevonden"
                            >
                              <!-- Column: Relations -->
                              <template #cell(relation)="data">
                                <b-media
                                  v-if="data.item.type === 1"
                                  vertical-align="center"
                                >
                                  <template #aside>
                                    <b-avatar
                                      size="32"
                                      :text="avatarText(getFullName(data.item))"
                                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                                    />
                                  </template>
                                  <b-link
                                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                                    class="font-weight-bold d-block text-nowrap"
                                  >
                                    {{ getFullName(data.item) }}
                                  </b-link>
                                  <div class="text-muted">
                                    {{ data.item.email }}
                                  </div>
                                </b-media>
                                <b-media
                                  v-if="data.item.type === 2"
                                  vertical-align="center"
                                >
                                  <template #aside>
                                    <b-avatar
                                      size="32"
                                      :text="avatarText(getFullName(data.item.companyName))"
                                      :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                                    />
                                  </template>
                                  <b-link
                                    :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                                    class="font-weight-bold d-block text-nowrap"
                                  >
                                    {{ data.item.companyName }}
                                  </b-link>
                                  <div class="text-muted">
                                    {{ getFullName(data.item) }}
                                  </div>
                                </b-media>
                              </template>

                              <template #cell(type)="data">
                                <div class="text-nowrap">
                                  <feather-icon
                                    v-if="data.item.type === 2"
                                    class="cursor-pointer"
                                    icon="HomeIcon"
                                    size="20"
                                  />
                                  <feather-icon
                                    v-if="data.item.type === 1"
                                    class="cursor-pointer"
                                    icon="UserIcon"
                                    size="20"
                                  />
                                  {{ resolveTypeVariant(data.value) }}
                                </div>
                                <div
                                  v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
                                  class="text-nowrap"
                                >
                                  <feather-icon
                                    class="cursor-pointer"
                                    icon="CompassIcon"
                                    size="20"
                                    :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
                                  />
                                  {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
                                </div>
                              </template>

                              <!-- Column: Actions -->
                              <template #cell(actions)="data">

                                <div class="text-nowrap">
                                  <b-link
                                    @click="addRelationToTheOffer(data.item)"
                                  >
                                    <feather-icon
                                      :id="`relation-row-${data.item.id}-preview-icon`"
                                      class="cursor-pointer mx-1"
                                      icon="PlusCircleIcon"
                                      size="18"
                                    />
                                  </b-link>
                                </div>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-text>
          </b-tab>
          <b-tab
            v-if="showAddNewRelationPolisHolder && relationTypeToAdd === 1||showAddNewRelationInsuredParty && relationTypeToAdd === 2"
          >
            <template #title>
              <feather-icon icon="UserPlusIcon" />
              <span>Nieuwe relatie aanmaken</span>
            </template>

            <b-card-text>
              <app-relation
                type="ADD"
                :show-back-button="false"
                :relation-data="relationData"
                :show-overlay="false"
                :handle-button-spinner="relationHandleButtonSpinner"
                handle-button-text="Aanmaken"
                @handle-relation="createRelation"
              />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-update-relation"
      ref="modalUpdateRelation"
      hide-footer
      centered
      size="xl"
      title="Relatie aanpassen"
      :no-close-on-backdrop="true"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon" />
          <span>Relatie</span>
        </template>
        <b-card-text>
          <app-relation
            type="UPDATE"
            :show-back-button="false"
            :relation-data="updateRelationData"
            :show-overlay="updateRelationOverlay"
            :handle-button-spinner="relationUpdateHandleButtonSpinner"
            handle-button-text="Aanpassen"
            @handle-relation="updateRelation"
          />
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-card
      v-if="$can('read', 'order-create-foruser')"
      no-body
    >
      <b-card-actions
        title="Order aanmaken voor een andere gebruikers_agent"
        action-collapse
      >
        <b-card-text>
          Indien deze order wilt aanmaken t.b.v. van een andere gebruikers_agent selecteer hieronder de desbetreffende gebruikers_agent, indien er geen gebruikers_agent geslecteerd word maak je de order aan voor de nu ingelogde gebruiker.
        </b-card-text>
        <b-card-body>
          <validation-provider
            #default="validationContext"
            name="forUserID"
          >
            <b-form-group
              label="Selecteer agent"
              label-for="forUserID"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="forUserID"
                v-model="polisInformation.forUserID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="usersData"
                label="fullname"
                :reduce="val => val.id"
                :clearable="true"
                :loading="showGetUsersLoader"
                @search="getUsers($event)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-card-body>
      </b-card-actions>
    </b-card>
    <b-card
      no-body
    >
      <b-card-actions
        title="Verzekeringsnemer"
        action-collapse
        :class="polisHolderLenght < 1 && !polisHolderWatchIsFirstTime ? 'invalid' : ''"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />

            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-if="polisHolderData.length < 1"
                  variant="primary"
                  @click="addRelation(1)"
                >
                  <span class="text-nowrap">Verzekeringsnemer Toevoegen</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refPolisHolderListTable"
          class="position-relative"
          :items="polisHolderData"
          responsive
          :fields="polisHolderTableColumns"
          show-empty
          empty-text="Geen overeenkomende records gevonden"
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            <b-link
              class="font-weight-bold"
              :to="{ name: 'apps-relations-edit', params: { id: data.item.id }}"
            >
              #{{ data.value }}
            </b-link>
          </template>

          <!-- Column: Relations -->
          <template #cell(relation)="data">
            <b-media
              v-if="data.item.type === 1"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(getFullName(data.item))"
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ getFullName(data.item) }}
              </b-link>
              <div class="text-muted">
                {{ data.item.email }}
              </div>
            </b-media>
            <b-media
              v-if="data.item.type === 2"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(getFullName(data.item.companyName))"
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.companyName }}
              </b-link>
              <div class="text-muted">
                {{ getFullName(data.item) }}
              </div>
            </b-media>
          </template>

          <template #cell(type)="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="data.item.type === 2"
                class="cursor-pointer"
                icon="HomeIcon"
                size="20"
              />
              <feather-icon
                v-if="data.item.type === 1"
                class="cursor-pointer"
                icon="UserIcon"
                size="20"
              />
              {{ resolveTypeVariant(data.value) }}
            </div>
            <div
              v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
              class="text-nowrap"
            >
              <feather-icon
                class="cursor-pointer"
                icon="CompassIcon"
                size="20"
                :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
              />
              {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
            </div>
          </template>

          <template #cell(phone)="data">
            <span class="text-nowrap">
              {{ data.item.type === 1 ? data.item.mobile : data.value }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                :id="`product-polis-holder-row-${data.item.id}-eye-icon`"
                class="cursor-pointer"
                size="16"
                icon="EyeIcon"
                style="color: #f4ad02;"
                @click="openUpdateRelationModal(data.item)"
              />
              <feather-icon
                v-if="polisHorderIsNotInsuredParty(data.item.id) && data.item.type === 1 && canAddInsuredPartiesBool"
                :id="`product-polis-holder-row-${data.item.id}-copy-icon`"
                class="cursor-pointer ml-1"
                size="16"
                icon="CopyIcon"
                style="color: #f4ad02;"
                @click="confirmCopyPolisHolderDataText(data.item)"
              />
              <feather-icon
                :id="`product-polis-holder-row-${data.item.id}-delete-icon`"
                class="cursor-pointer ml-1"
                size="16"
                icon="TrashIcon"
                style="color: #f4ad02;"
                @click="confirmPolisHolderDataDeleteText(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card-actions>
    </b-card>
    <b-card
      v-if="selectedProduct.type === 2"
      no-body
    >
      <b-card-actions
        title="Meeverzekerde"
        action-collapse
      >
        <validation-observer
          ref="refInsuredPartiesTypeObserver"
        >
          <b-form>
            <b-row class="mb-1 mt-0">
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Wilt u een partner of kind(eren) mee verzekeren?"
                  label-for="additionalInsuranceType"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="additionalInsuranceType"
                      name="additionalInsuranceType"
                      :value="0"
                      class="custom-control-primary"
                      :disabled="insuredPartiesLenght > 0"
                    >
                      Geen
                    </b-form-radio>
                    <b-form-radio
                      v-model="additionalInsuranceType"
                      name="additionalInsuranceType"
                      :value="1"
                      class="custom-control-primary"
                      :disabled="insuredPartiesLenght > 0"
                    >
                      Partner
                    </b-form-radio>
                    <b-form-radio
                      v-model="additionalInsuranceType"
                      name="additionalInsuranceType"
                      :value="2"
                      class="custom-control-primary"
                      :disabled="insuredPartiesLenght > 0"
                    >
                      Kind(eren)
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-actions>
    </b-card>
    <b-card
      no-body
    >
      <b-card-actions
        title="Verzekerde"
        action-collapse
        :class="insuredPartiesLenght < 1 && !insuredPartiesWatchIsFirstTime || insuredPartiesLenghtError ? 'invalid':''"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />
            <b-col
              v-if="canAddInsuredPartiesBool"
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="addRelation(2)"
                >
                  <span class="text-nowrap">Verzekerde Toevoegen</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refInsuredPartiesListTable"
          class="position-relative"
          :items="insuredPartiesData"
          responsive
          :fields="insuredPartiesTableColumns"
          show-empty
          empty-text="Geen overeenkomende records gevonden"
        >
          <!-- Column: Id -->
          <template #cell(id)="data">
            <!--            <b-link-->
            <!--              :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"-->
            <!--              class="font-weight-bold"-->
            <!--            >-->
            #{{ data.value }}
            <!--            </b-link>-->
          </template>

          <!-- Column: Relations -->
          <template #cell(relation)="data">
            <b-media
              v-if="data.item.type === 1"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(getFullName(data.item))"
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ getFullName(data.item) }}
              </b-link>
              <div class="text-muted">
                {{ data.item.email }}
              </div>
            </b-media>
            <b-media
              v-if="data.item.type === 2"
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(getFullName(data.item.companyName))"
                  :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-relations-edit', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.companyName }}
              </b-link>
              <div class="text-muted">
                {{ getFullName(data.item) }}
              </div>
            </b-media>
          </template>

          <template #cell(phone)="data">
            <span class="text-nowrap">
              {{ data.item.type === 1 ? data.item.mobile : data.value }}
            </span>
          </template>

          <template #cell(type)="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="data.item.type === 2"
                class="cursor-pointer"
                icon="HomeIcon"
                size="20"
              />
              <feather-icon
                v-if="data.item.type === 1"
                class="cursor-pointer"
                icon="UserIcon"
                size="20"
              />
              {{ resolveTypeVariant(data.value) }}
            </div>
            <div
              v-if="data.item.riskProfile && data.item.riskProfile !== '' && data.item.riskProfile !== 'NONE'"
              class="text-nowrap"
            >
              <feather-icon
                class="cursor-pointer"
                icon="CompassIcon"
                size="20"
                :style="`color:${resolveRiskProfileColor(data.item.riskProfile)};`"
              />
              {{ data.item.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ data.item.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ data.item.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`product-insured-party-row-${data.item.id}-eye-icon`"
                class="cursor-pointer"
                size="16"
                icon="EyeIcon"
                style="color: #f4ad02;"
                @click="openUpdateRelationModal(data.item)"
              />
              <feather-icon
                :id="`product-insured-party-row-${data.item.id}-delete-icon`"
                class="cursor-pointer ml-1"
                size="16"
                icon="TrashIcon"
                style="color: #f4ad02;"
                @click="confirmInsuredPartyDeleteText(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </b-card-actions>
    </b-card>
    <b-card
      v-if="insuredPartiesData.length > 1 || selectedProduct.type === 2 && additionalInsuranceType === 1 && insuredPartiesData.length > 0 || selectedProduct.type === 2 && (additionalInsuranceType === 2 || additionalInsuranceType === 0) && polisHolderData.length > 0"
      no-body
    >
      <b-card-actions
        title="Hoofdverzekerde"
        action-collapse
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <div class="demo-inline-spacing mb-2">
              <validation-observer
                ref="refIsMainInsuredPartyFormObserver"
              >
                <b-form>
                  <validation-provider
                    #default="validationContext"
                    name="isMainInsuredParty"
                    rules="required"
                  >
                    <b-form-group
                      label="Wie is de hoofdverzekerde?"
                      label-for="isMainInsuredParty"
                    >
                      <template
                        v-if="selectedProduct.type === 2 && (additionalInsuranceType === 2 || additionalInsuranceType === 0)"
                      >
                        <b-form-radio
                          v-for="(party, index) in polisHolderData"
                          :key="`insured-main-party-${index}`"
                          v-model="isMainInsuredParty"
                          name="companyOrConsumer"
                          :value="party.id"
                          :state="getValidationState(validationContext)"
                        >{{ getFullName(party) }}</b-form-radio>
                      </template>
                      <template
                        v-if="selectedProduct.type === 1 || selectedProduct.type === 2 && additionalInsuranceType === 1"
                      >
                        <b-form-radio
                          v-for="(party, index) in insuredPartiesData"
                          :key="`insured-main-party-${index}`"
                          v-model="isMainInsuredParty"
                          name="companyOrConsumer"
                          :value="party.id"
                          :state="getValidationState(validationContext)"
                        >{{ getFullName(party) }}</b-form-radio>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </template>
                    </b-form-group>
                  </validation-provider>
                </b-form>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </b-card-actions>
    </b-card>
    <b-card
      v-if="selectedProduct.maxBenificiaries > 0"
      no-body
    >
      <b-card-actions
        title="Begunstigden"
        action-collapse
      >
        <div class="m-2">
          <b-row>
            <b-col cols="12">
              <div>
                <validation-observer
                  ref="refCaredForFormObserver"
                >
                  <b-form
                    ref="caredForForm"
                    :style="{height: trHeight}"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >
                    <!-- Row Loop -->
                    <b-row
                      v-for="(item, index) in benificiaries"
                      :id="item.repeatId"
                      :key="item.repeatId"
                      ref="row"
                    >
                      <b-col
                        cols="10"
                      >
                        <label :label-for="`companyOrConsumer${index}`">Particulier / Zakelijk</label>
                        <validation-provider
                          #default="validationContext"
                          :name="`companyOrConsumer${index}`"
                          rules="required"
                        >
                          <div class="demo-inline-spacing mb-2">
                            <b-form-radio
                              v-model="item.type"
                              class="mt-0"
                              :name="`companyOrConsumer${index}`"
                              :value="1"
                              :state="getValidationState(validationContext)"
                            >
                              Particulier
                            </b-form-radio>
                            <b-form-radio
                              v-model="item.type"
                              class="mt-0"
                              :name="`companyOrConsumer${index}`"
                              :value="2"
                              :state="getValidationState(validationContext)"
                            >
                              Zakelijk
                            </b-form-radio>
                          </div>
                        </validation-provider>
                      </b-col>
                      <b-col
                        class="text-right align-text-center align-self-end"
                        cols="2"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-25"
                          />
                        </b-button>
                      </b-col>
                      <b-col
                        v-if="item.type === 2"
                        md="5"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="`companyName${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="Bedrijfsnaam"
                            :label-for="`companyName${index}`"
                          >
                            <b-form-input
                              :id="`companyName${index}`"
                              v-model="item.companyName"
                              :state="getValidationState(validationContext)"
                            />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Item Name -->
                      <b-col
                        v-if="item.type === 1"
                        md="5"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="`itemFirstName${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="Voornaam"
                            :label-for="`itemFirstName${index}`"
                          >
                            <b-form-input
                              :id="`itemFirstName${index}`"
                              v-model="item.firstName"
                              type="text"
                              :state="getValidationState(validationContext)"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- Item Name -->
                      <b-col
                        v-if="item.type === 1"
                        md="2"
                      >
                        <b-form-group
                          label="Tussenvoegsel"
                          :label-for="`itemMiddleName${index}`"
                        >
                          <b-form-input
                            :id="`itemMiddleName${index}`"
                            v-model="item.middleName"
                            type="text"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="item.type === 1"
                        md="5"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="`itemLastName${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="Achternaam"
                            :label-for="`itemLastName${index}`"
                          >
                            <b-form-input
                              :id="`itemLastName${index}`"
                              v-model="item.lastName"
                              type="text"
                              :state="getValidationState(validationContext)"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col
                        v-if="item.type === 1"
                        md="3"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="`itemBirthDate${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="Geboortedatum"
                            :label-for="`itemBirthDate${index}`"
                            :state="getValidationState(validationContext)"
                          >
                            <flat-pickr
                              :id="`itemBirthDate${index}`"
                              v-model="item.birthDate"
                              :config="birthDate2Config"
                              :state="getValidationState(validationContext)"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col
                        v-if="item.type === 1 || item.type === 2"
                        md="5"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="`itemRelationDescriptionDate${index}`"
                          rules="required"
                        >
                          <b-form-group
                            label="Relatieomschrijving"
                            :label-for="`itemRelationDescriptionDate${index}`"
                          >
                            <b-form-input
                              :id="`itemRelationDescriptionDate${index}`"
                              v-model="item.relationDescription"
                              type="text"
                              :state="getValidationState(validationContext)"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>

                  </b-form>
                </validation-observer>
              </div>
              <b-button
                v-if="benificiaries.length < selectedProduct.maxBenificiaries"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Toevoegen</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </b-card>
    <b-card
      no-body
    >
      <b-card-actions
        title="Informatie"
        action-collapse
      >
        <validation-observer
          ref="refFormPolisInformationObserver"
        >
          <b-form
            id="orderInformation"
          >
            <b-row class="mb-1 mt-0">
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="insuredAmount"
                  :rules="`required|between:${minInsuredAmount},${maxInsuredAmount}`"
                >
                  <b-form-group
                    :label="selectedProduct.type === 2 ? 'Jaarloon' : 'Verzekerd Kapitaal'"
                    label-for="insuredAmount"
                  >
                    <b-form-input
                      id="insuredAmount"
                      v-model="polisInformation.insuredAmount"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Valuta"
                  label-for="creditAmountCurrency"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="polisInformation.currency"
                      name="currency"
                      value="SRD"
                      class="custom-control-primary"
                    >
                      SRD
                    </b-form-radio>
                    <b-form-radio
                      v-model="polisInformation.currency"
                      name="currency"
                      value="USD"
                      class="custom-control-primary"
                      :disabled="selectedProduct.allowForeignCurrency === 0"
                    >
                      USD
                    </b-form-radio>
                    <b-form-radio
                      v-model="polisInformation.currency"
                      name="currency"
                      value="EUR"
                      class="custom-control-primary"
                      :disabled="selectedProduct.allowForeignCurrency === 0"
                    >
                      EUR
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="selectedProduct.type === 1"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="startDate"
                  rules="required"
                >
                  <b-form-group
                    label="Looptijd"
                    label-for="contractDuration"
                    rules="required"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="polisInformation.contractDuration"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="contractDurationOptions"
                      :reduce="val => val"
                      :clearable="false"
                      input-id="active"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  ref="startDateValidationProvider"
                  #default="validationContext"
                  name="startDate"
                  rules="required"
                >
                  <b-form-group
                    label="Startdatum"
                    label-for="startDate"
                  >
                    <flat-pickr
                      id="startDate"
                      v-model="polisInformation.startDate"
                      :config="startDateConfig"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="selectedProduct.type === 2"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="employmentDate"
                  rules="required"
                >
                  <b-form-group
                    label="Datum indiensttreding"
                    label-for="employmentDate"
                  >
                    <flat-pickr
                      id="employmentDate"
                      v-model="polisInformation.employmentDate"
                      :config="employmentDateConfig"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="paymentMethod"
                  rules="required"
                >
                  <b-form-group
                    label="Betaalmethode"
                    label-for="paymentMethod"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="polisInformation.paymentMethod"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentMethodOptions"
                      :reduce="val => val"
                      :clearable="false"
                      input-id="paymentMethod"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="paymentFrequency"
                  rules="required"
                >
                  <b-form-group
                    label="Betaalfrequentie"
                    label-for="paymentFrequency"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="polisInformation.paymentFrequency"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentFrequencyOptions"
                      :reduce="val => val"
                      :clearable="false"
                      input-id="paymentFrequency"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1 mt-0">
              <b-col
                v-if="selectedProduct.type === 2 && polisHolderData.length > 0 && polisHolderData[0].type === 2"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="employerContributionPercentage"
                  rules="required|integer|between:50,100"
                >
                  <b-form-group
                    label="Werkgeversbijdrage (%)"
                    label-for="employerContributionPercentage"
                  >
                    <b-form-input
                      id="employerContributionPercentage"
                      v-model="polisInformation.employerContributionPercentage"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="selectedProduct.type === 2 && additionalInsuranceType == 1"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="partnerPensionType"
                  rules="required"
                >
                  <b-form-group
                    label="Partner verzekeringstype"
                    label-for="partnerPensionType  "
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="polisInformation.partnerPensionType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="partnerInsuranceTypeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="partnerPensionType "
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Is het een Collectief?"
                  label-for="hasCollective"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="polisInformation.hasCollective"
                      name="hasCollective"
                      :value="false"
                      class="custom-control-primary"
                    >
                      Nee
                    </b-form-radio>
                    <b-form-radio
                      v-model="polisInformation.hasCollective"
                      name="hasCollective"
                      :value="true"
                      class="custom-control-primary"
                    >
                      Ja
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="polisInformation.hasCollective"
                cols="12"
                md="6"
              >
                <validation-provider
                  #default="validationContext"
                  name="collectiveID"
                  rules="required"
                >
                  <b-form-group
                    label="Collectief"
                    label-for="collectiveID"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="polisInformation.collectiveID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="collectivesOptions"
                      :reduce="val => val.id"
                      label="name"
                      :clearable="false"
                      input-id="collectiveID"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-actions>
    </b-card>
    <b-row
      v-if="pageHasError === true"
    >
      <b-col
        cols="12"
      >
        <b-alert
          show
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">Niet alle verplichte velden zijn ingevuld. Vul deze alsnog in, en probeer het nogmaals.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="6 text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$emit('previous-step')"
              >
                <span>Vorige</span>
              </b-button>
            </b-col>
            <b-col
              cols="6 text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onSubmit"
              >
                <span>Volgende</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BTable,
  BFormRadio,
  BModal,
  VBModal,
  BTabs,
  BTab, BLink, BMedia, BAvatar,
  BAlert, BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  required,
  integer,
  between,
  requiredIf,
  min,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import useOrderFlow from '@/views/pages/order-flow/useOrderFlow'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { avatarText } from '@core/utils/filter'
import { removeFromArray, todayMinYears } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'
import AppRelation from '@core/components/app-relation/AppRelation.vue'
import useRelationsList from '@/views/pages/relations/useRelationsList'

export default {
  components: {
    BForm,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BTable,
    BFormRadio,
    BModal,
    BTabs,
    BTab,
    BCardActions,
    BLink,
    BMedia,
    BAvatar,
    BAlert,
    flatPickr,
    BCardBody,
    AppRelation,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    maxInsuredRelations: {
      type: Number,
      default: () => 0,
    },
    selectedProduct: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      usersData: [],
      employmentDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      searchBirthDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      birthDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      birthDate2Config: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      isIssueDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      idExpiryDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      existingRelations: [],
      benificiaries: [],
      relationData: {
        bankCountry: null,
        bankCurrency: null,
        bankName: null,
        bankNumber: null,
        birthCountry: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        companyActivity: null,
        companyBaseNumber: null,
        companyEstablishmentCountry: null,
        companyEstablishmentDate: null,
        companyFoundationRegisterNumber: null,
        companyKKFNumber: null,
        companyLegalForm: null,
        companyName: null,
        companyTaxNumber: null,
        country: null,
        district: null,
        documentStatus: null,
        email: null,
        files: [],
        firstName: null,
        gender: null,
        houseNumber: null,
        id: null,
        idExpiryDate: null,
        idIssuingCountry: null,
        idNumber: null,
        idType: null,
        isIssueDate: null,
        isPEP: null,
        isResident: null,
        lastName: null,
        maidenMiddleName: null,
        maidenName: null,
        maritalStatus: null,
        middleName: null,
        mobile: null,
        nationality: null,
        occupation: null,
        occupationSector: null,
        phone: null,
        riskProfile: null,
        street: null,
        type: 1,
        whatsappPhone: null,
      },
      updateRelationData: {
        bankCountry: null,
        bankCurrency: null,
        bankName: null,
        bankNumber: null,
        birthCountry: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        companyActivity: null,
        companyBaseNumber: null,
        companyEstablishmentCountry: null,
        companyEstablishmentDate: null,
        companyFoundationRegisterNumber: null,
        companyKKFNumber: null,
        companyLegalForm: null,
        companyName: null,
        companyTaxNumber: null,
        country: null,
        district: null,
        documentStatus: null,
        email: null,
        files: [],
        firstName: null,
        gender: null,
        houseNumber: null,
        id: null,
        idExpiryDate: null,
        idIssuingCountry: null,
        idNumber: null,
        idType: null,
        isIssueDate: null,
        isPEP: null,
        isResident: null,
        lastName: null,
        maidenMiddleName: null,
        maidenName: null,
        maritalStatus: null,
        middleName: null,
        mobile: null,
        nationality: null,
        occupation: null,
        occupationSector: null,
        phone: null,
        riskProfile: null,
        street: null,
        type: 1,
        whatsappPhone: null,
      },
      blankRelationData: {
        bankCountry: null,
        bankCurrency: null,
        bankName: null,
        bankNumber: null,
        birthCountry: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        companyActivity: null,
        companyBaseNumber: null,
        companyEstablishmentCountry: null,
        companyEstablishmentDate: null,
        companyFoundationRegisterNumber: null,
        companyKKFNumber: null,
        companyLegalForm: null,
        companyName: null,
        companyTaxNumber: null,
        country: null,
        district: null,
        documentStatus: null,
        email: null,
        files: [],
        firstName: null,
        gender: null,
        houseNumber: null,
        id: null,
        idExpiryDate: null,
        idIssuingCountry: null,
        idNumber: null,
        idType: null,
        isIssueDate: null,
        isPEP: null,
        isResident: null,
        lastName: null,
        maidenMiddleName: null,
        maidenName: null,
        maritalStatus: null,
        middleName: null,
        mobile: null,
        nationality: null,
        occupation: null,
        occupationSector: null,
        phone: null,
        riskProfile: null,
        street: null,
        type: 1,
        whatsappPhone: null,
      },
      relationSearchQuery: {
        lastName: '',
        birthDate: '',
        searchCompanyName: '',
        searchType: 1,
      },
      blankRelationSearchQuery: {
        lastName: '',
        birthDate: '',
      },
      nextId: 0,
      caredForData: {},
      relationTypeToAdd: 0,
      polisHolderData: [],
      insuredPartiesData: [],
      insuredPartiesId: [],
      insuredPartiesString: '',
      polisInformation: {
        forUserID: null,
        currency: 'SRD',
        contractDuration: null,
        insuredAmount: null,
        paymentMethod: '',
        paymentFrequency: '',
        collectiveID: null,
        hasCollective: false,
        employerContributionPercentage: 0,
        additionalInsuranceType: 0,
        isMainInsuredParty: 0,
        employmentDate: '',
        partnerPensionType: null,
      },
      paymentFrequencyOptions: ['Jaarlijks'],
      paymentMethodOptions: ['Bank', 'Kas'],
      pageHasError: null,
      polisHolderLenght: null,
      polisHolderWatchIsFirstTime: true,
      insuredPartiesLenght: null,
      insuredPartiesWatchIsFirstTime: true,
      isMainInsuredParty: null,
      minInsuredAmount: 0,
      maxInsuredAmount: 0,
      contractDurationOptions: [],
      canAddInsuredPartiesBool: false,
      additionalInsuranceType: 0,
      collectivesOptions: [],
      insuredPartiesLenghtError: false,
      showAddNewRelationPolisHolder: false,
      showAddNewRelationInsuredParty: false,
      showGetUsersLoader: false,
      relationHandleButtonSpinner: false,
      relationUpdateHandleButtonSpinner: false,
      updateRelationOverlay: false,
    }
  },
  computed: {
    startDateConfig() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.companyName.toLowerCase().includes('self reliance')) {
        return {
          minDate: null,
          altFormat: 'd-m-Y',
          dateFormat: 'Y-m-d',
          altInput: true,
          allowInput: true,
        }
      }

      return {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      }
    },
  },
  watch: {
    additionalInsuranceType: {
      handler() {
        this.canAddInsuredParties()
      },
      deep: true,
    },
    polisInformation: {
      handler() {
        this.setMinMaxInsuredAmount()
      },
      deep: true,
    },
    polisHolderData: {
      handler() {
        this.polisHolderLenght = this.polisHolderData.length
      },
      deep: true,
    },
    insuredPartiesId: {
      handler() {
        this.insuredPartiesLenght = this.insuredPartiesId.length
      },
      deep: true,
    },
    selectedProduct: {
      handler() {
        if (this.selectedProduct.hasPartialPayment === 1) {
          this.paymentFrequencyOptions = ['Maandelijks', 'Kwartaal', 'Halfjaarlijks', 'Jaarlijks']
        }

        if (!this.selectedProduct.hasDurationLimitByAge || this.selectedProduct.hasDurationLimitByAge === 0) {
          this.setMinMaxInsuredAmount()
        }

        this.canAddInsuredParties()
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.$ability.can('read', 'order-create-foruser')) {
      this.fetchUsers(null)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      this.relationData = this.blankRelationData
      this.relationSearchQuery = this.blankRelationSearchQuery
      this.existingRelations = []
    })

    store
      .dispatch('apps-order-flow-checkout/fetchCollectives', {
        sortBy: 'name',
      })
      .then(response => {
        this.collectivesOptions = response.data.items
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de collectieven',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  },
  created() {
    this.initTrHeight()
    window.addEventListener('resize', this.initTrHeight)

    this.canAddInsuredParties()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getUsers(q) {
      if (this.$ability.can('read', 'order-create-foruser')) {
        this.fetchUsers(q)
      }
    },
    fetchUsers(q) {
      this.showGetUsersLoader = true
      store
        .dispatch('apps-order-flow-checkout/fetchUsers', {
          sortDesc: false,
          sortBy: 'lastname',
          perPage: 100,
          q,
        })
        .then(response => {
          this.usersData = response.data.items
          this.showGetUsersLoader = false
        })
        .catch(() => {
          this.showGetUsersLoader = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen gebruikers',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
    canAddInsuredParties() {
      /**
       * Check lenght of insured parties if type is 1 (TOR / Begrafenis)
       */
      if (this.selectedProduct.type === 1) {
        this.canAddInsuredPartiesBool = this.insuredPartiesData.length < this.selectedProduct.maxInsuredRelations
      }

      /**
       * Always return true if product type is 2 (APSR)
       */
      if (this.selectedProduct.type === 2) {
        if (this.insuredPartiesData.length === 2 && this.additionalInsuranceType === 1) {
          this.canAddInsuredPartiesBool = false
        } else if (this.insuredPartiesData.length === 1 && this.additionalInsuranceType === 0) {
          this.canAddInsuredPartiesBool = false
        } else {
          this.canAddInsuredPartiesBool = true
        }
      }
    },
    setDuration() {
      for (let i = 1; i <= this.selectedProduct.maxContractDuration; i += 1) {
        if (i >= this.selectedProduct.minContractDuration) {
          this.contractDurationOptions.push(i)
        }
      }
    },
    getMaxDurationByRelation() {
      store
        .dispatch('apps-order-flow-checkout/getMaxDurationByRelation', { productID: this.selectedProduct.id, relationIDs: this.insuredPartiesString.substring(0, this.insuredPartiesString.length - 1) })
        .then(response => {
          this.polisInformation.contractDuration = null
          this.contractDurationOptions = []
          const minDuration = response.data.minDuration ? response.data.minDuration : response.data.maxDuration
          for (let i = 0; i < response.data.maxDuration; i += 1) {
            if ((i + 1) >= minDuration) {
              this.contractDurationOptions.push(i + 1)
            }
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het berekenen van de looptijd',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
    setMinMaxInsuredAmount() {
      if (this.polisInformation.currency === 'SRD') {
        this.minInsuredAmount = this.selectedProduct.minInsuredAmountSRD
        this.maxInsuredAmount = this.selectedProduct.maxInsuredAmountSRD
      }

      if (this.polisInformation.currency === 'USD') {
        this.minInsuredAmount = this.selectedProduct.minInsuredAmountUSD
        this.maxInsuredAmount = this.selectedProduct.maxInsuredAmountUSD
      }

      if (this.polisInformation.currency === 'EUR') {
        this.minInsuredAmount = this.selectedProduct.minInsuredAmountEUR
        this.maxInsuredAmount = this.selectedProduct.maxInsuredAmountEUR
      }
    },
    searchForExistingRelations() {
      store.dispatch('apps-order-flow-checkout/searchForRelations', { data: this.relationSearchQuery })
        .then(response => {
          if (this.relationTypeToAdd === 1) {
            this.showAddNewRelationPolisHolder = true
          }

          if (this.relationTypeToAdd === 2) {
            this.showAddNewRelationInsuredParty = true
          }
          this.existingRelations = response.data.items
        })
    },
    openUpdateRelationModal(data) {
      this.updateRelationOverlay = true
      this.$refs.modalUpdateRelation.show()
      // eslint-disable-next-line no-return-assign
      setTimeout(() => {
        this.updateRelationData = JSON.parse(JSON.stringify(data))
        this.updateRelationOverlay = false
      }, 500)
    },
    addRelation(type) {
      this.relationTypeToAdd = type
      this.$refs.modalAddRelation.show()
    },
    createRelation(data) {
      this.relationHandleButtonSpinner = true
      store.dispatch('apps-order-flow-checkout/addRelation', data)
        .then(response => {
          this.relationHandleButtonSpinner = false
          this.addRelationToTheOffer(response.data)
          this.relationData = this.blankRelationData
        })
        .catch(() => {
          this.relationHandleButtonSpinner = false
          this.$swal({
            icon: 'error',
            title: 'Relatie!',
            text: 'Er is een fout opgetreden bij het aanmaken van de relatie. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    updateRelation(data) {
      this.relationUpdateHandleButtonSpinner = true
      store.dispatch('apps-order-flow-checkout/updateRelation', data)
        .then(() => {
          const insuredPartyItem = this.insuredPartiesData.find(i => i.id === data.id)
          const polisHolderItem = this.polisHolderData.find(p => p.id === data.id)

          if (insuredPartyItem) {
            this.insuredPartiesData.splice(this.insuredPartiesData.indexOf(insuredPartyItem), 1, data)
          }

          if (insuredPartyItem) {
            this.polisHolderData.splice(this.polisHolderData.indexOf(polisHolderItem), 1, data)
          }

          this.relationUpdateHandleButtonSpinner = false
          this.$refs.modalUpdateRelation.hide()
          this.updateRelationData = this.blankRelationData
        })
        .catch(() => {
          this.relationUpdateHandleButtonSpinner = false
          this.$refs.modalUpdateRelation.hide()
          this.$swal({
            icon: 'error',
            title: 'Relatie updaten!',
            text: 'Er is een fout opgetreden bij het updaten van de relatie. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    addRelationToTheOffer(data) {
      this.relationSearchQuery = this.blankRelationSearchQuery
      if (this.relationTypeToAdd === 1) {
        this.polisHolderData.push(data)
        this.existingRelations = []
        this.$refs.modalAddRelation.hide()
      } else if (this.relationTypeToAdd === 2) {
        if (!this.insuredPartiesData.some(relation => JSON.stringify(relation) === JSON.stringify(data))) {
          this.insuredPartiesData.push(data)
          this.insuredPartiesId.push({ id: data.id })
          this.insuredPartiesString += `${data.id},`
          if (this.selectedProduct.hasDurationLimitByAge === 1) {
            this.getMaxDurationByRelation()
          } else {
            this.setDuration()
          }

          this.existingRelations = []
          this.canAddInsuredParties()
          this.$refs.modalAddRelation.hide()
        } else {
          this.existingRelations = []
          this.$refs.modalAddRelation.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Toevoegen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'De verzekerde is al toegevoegd.',
            },
          })
        }
      }
    },
    setMainInsuredParty() {
      if (this.insuredPartiesId.length > 1) {
        for (let i = 0; i < this.insuredPartiesId.length; i += 1) {
          if (this.insuredPartiesId[i].id === this.isMainInsuredParty) {
            this.insuredPartiesId[i].isMain = true
            this.polisInformation.isMainInsuredParty = this.insuredPartiesId[i].id
          } else {
            this.insuredPartiesId[i].isMain = false
          }
        }
      } else if (this.insuredPartiesId.length === 1) {
        this.insuredPartiesId[0].isMain = true
        this.polisInformation.isMainInsuredParty = this.insuredPartiesId[0].id
      }
    },
    countryChanged(countryCode) {
      this.relationData.district = ''
      this.districtOptions = this.resolveDistrictForCountry(countryCode)
    },
    repeateAgain() {
      this.benificiaries.push({
        repeatId: this.nextId += 1,
        firstName: '',
        middleName: '',
        lastName: '',
        relationDescription: '',
        birthDate: '',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.benificiaries.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.refPolisHolderListTable.scrollHeight)
      })
    },
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
    polisHorderIsNotInsuredParty(id) {
      const item = this.insuredPartiesData.find(i => i.id === id)

      if (item) {
        return false
      }

      return true
    },
    confirmCopyPolisHolderDataText(data) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'De verzekeringsnemer word toegevoegd als verzekerde.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Ja, voeg toe!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.relationTypeToAdd = 2
          this.addRelationToTheOffer(data)
        }
      })
    },
    confirmPolisHolderDataDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.polisHolderData = removeFromArray({
            array: this.polisHolderData,
            key: 'id',
            value: id,
          })

          this.refetchInsuredPartiesData()
        }
      })
    },
    confirmInsuredPartyDeleteText(id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, verwijder het!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.insuredPartiesData = removeFromArray({
            array: this.insuredPartiesData,
            key: 'id',
            value: id,
          })

          this.insuredPartiesId = removeFromArray({
            array: this.insuredPartiesId,
            key: 'id',
            value: id,
          })

          this.insuredPartiesString = this.insuredPartiesString.replace(`${id},`, '')

          this.refetchPolisHolderData()
          this.canAddInsuredParties()
        }
      })
    },
    clearPageError() {
      this.pageHasError = false
    },
    async reValidateSearschForm() {
      await this.$refs.refFormSearchRelationObserver.validate()
    },
    resetStep() {
      this.contractDurationOptions = []
      this.polisInformation = {
        forUserID: null,
        currency: 'SRD',
        contractDuration: null,
        startDate: '',
        insuredAmount: null,
        paymentMethod: '',
        paymentFrequency: '',
        collectiveID: null,
        hasCollective: false,
        employerContributionPercentage: 0,
        additionalInsuranceType: 0,
        isMainInsuredParty: 0,
        employmentDate: '',
        partnerPensionType: null,
      }

      this.$refs.startDateValidationProvider.reset()
      this.polisHolderData = []
      this.insuredPartiesId = []
      this.insuredPartiesData = []
      this.benificiaries = []

      this.pageHasError = false
      this.insuredPartiesLenghtError = false
      this.polisHolderWatchIsFirstTime = true
      this.insuredPartiesLenght = null
      this.insuredPartiesWatchIsFirstTime = true

      if (this.refIsMainInsuredPartyFormObserver) {
        this.refIsMainInsuredPartyFormObserver.reset()
      }
      this.$refs.refFormPolisInformationObserver.reset()
      if (this.refCaredForFormObserver) {
        this.refCaredForFormObserver.reset()
      }
    },
    async onSubmit() {
      this.insuredPartiesWatchIsFirstTime = false
      this.polisHolderWatchIsFirstTime = false
      const formsToValidate = [this.$refs.refFormPolisInformationObserver.validate()]

      if (this.insuredPartiesData.length > 1) {
        formsToValidate.push(this.refIsMainInsuredPartyFormObserver.validate())
      }

      if (this.benificiaries.length > 0) {
        formsToValidate.push(this.refCaredForFormObserver.validate())
      }

      await Promise.all(formsToValidate)
        .then(valid => {
          if (this.insuredPartiesData.length < this.selectedProduct.minInsuredRelations) {
            this.pageHasError = true
            this.insuredPartiesLenghtError = true
            return
          }

          if (this.insuredPartiesData.length >= this.selectedProduct.minInsuredRelations) {
            this.pageHasError = false
            this.insuredPartiesLenghtError = false
          }

          if (!valid.includes(false) && this.polisHolderData.length > 0 && this.insuredPartiesId.length > 0) {
            this.setMainInsuredParty()

            /**
             *
             * Update additionalInsuranceType & isMainInsuredParty on the polisInformation before going on
             *
             */
            this.polisInformation.additionalInsuranceType = this.additionalInsuranceType

            this.$emit('set-polis-information', {
              polisInformation: this.polisInformation,
              polisHolderData: this.polisHolderData,
              insuredPartiesId: this.insuredPartiesId,
              insuredPartiesData: this.insuredPartiesData,
              benificiaries: this.benificiaries,
            })

            this.$emit('next-step')
          } else {
            this.pageHasError = true
            setTimeout(this.clearPageError, 5000)
          }
        })
    },
  },
  setup() {
    const refFormPolisInformationObserver = ref(null)
    const refFormSearchRelationObserver = ref(null)
    const refIsMainInsuredPartyFormObserver = ref(null)
    const refCaredForFormObserver = ref(null)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const {
      resolveTypeVariant,
      resolveRiskProfileColor,
    } = useRelationsList()

    const {
      insuredPartiesTableColumns,
      polisHolderTableColumns,
      fetchInsuredParties,
      refInsuredPartiesListTable,
      refPolisHolderListTable,
      fetchPolisHolderData,
      addInsuredParties,
      countryOptions,
      districtOptions,
      idTypeOptions,
      resolveDistrictForCountry,
      refetchInsuredPartiesData,
      refetchPolisHolderData,
      legalFormOptions,
      partnerInsuranceTypeOptions,
      martialOptions,
    } = useOrderFlow()

    return {
      refInsuredPartiesListTable,
      refPolisHolderListTable,
      insuredPartiesTableColumns,
      polisHolderTableColumns,
      countryOptions,
      districtOptions,
      idTypeOptions,
      resolveDistrictForCountry,
      fetchPolisHolderData,
      refetchInsuredPartiesData,
      refetchPolisHolderData,
      refFormPolisInformationObserver,
      refFormSearchRelationObserver,
      refIsMainInsuredPartyFormObserver,
      refCaredForFormObserver,
      refFormObserver,
      getValidationState,
      resetForm,
      fetchInsuredParties,
      addInsuredParties,
      avatarText,
      resolveTypeVariant,
      resolveRiskProfileColor,
      required,
      integer,
      between,
      requiredIf,
      min,
      todayMinYears,
      legalFormOptions,
      partnerInsuranceTypeOptions,
      martialOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.ecommerce-application .product-checkout.list-view {
  grid-template-columns: 2fr !important;
  column-gap: 2rem;
}

[dir] .vs--searchable .vs__dropdown-toggle{
  padding: 5px;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

[dir=ltr] .custom-checkbox.custom-control, [dir=ltr] .custom-radio.custom-control{
  margin-top: 10px;
}

.invalid{
  .card-title{
    color: #ea5455
  }
}
</style>
<style scoped>
.repeater-form {
  overflow: visible !important;
}
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
